
import { CurrentView, FloorViewMode } from '~/store/building/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import { isMobile } from '~/helpers/mobile/DeviceType'

export default {
  name: 'Organism3DModelInteraction',
  data() {
    return {
      viewModes: FloorViewMode,
      isSpaceSelectionOpen: false,
      showCartSpacesMobile: !isMobile()
    }
  },
  computed: {
    isMobileDevice() {
      return isMobile() || isTablet();
    },
    buildingStore() {
      return this.$store.state.building
    },
    fitoutData() {
      return this.buildingStore.fitoutData.data
    },
    isFloorView() {
      return this.buildingStore.currentView === CurrentView.FLOOR
    },
    viewMode() {
      return this.buildingStore.floorViewMode
    },
    currentSpace() {
      return this.buildingStore.space.spaceData || {}
    },
    spaceBuilding() {
      return this.$store.state.project.project.buildings.find(
        (b) => b.id === this.currentSpace.building_id
      )
    },
    spaceFloor() {
      return this.$store.state.project.project.floors.find(
        (f) => f.id === this.currentSpace.floor_id
      )
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    }
  },
  watch: {
    viewMode: function (value, old) {
      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem
        })
      }
      if (value !== old) {
        switch (value) {
          case this.viewModes.FIRST_PERSON:
            this.$refs.buttonVt.querySelector('span').classList.add('active')
            getSiblings(this.$refs.buttonVt).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active')
            })
            this.$refs.sliderComponent.classList.remove('active')
            break
          case this.viewModes.TWO_DIMENSIONAL:
            this.$refs.button2d.querySelector('span').classList.add('active')
            getSiblings(this.$refs.button2d).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active')
              this.$refs.sliderComponent.classList.add('active')
            })
            break
          case this.viewModes.DEFAULT:
            this.$refs.button3d.querySelector('span').classList.add('active')
            getSiblings(this.$refs.button3d).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active')
              this.$refs.sliderComponent.classList.add('active')
            })
            break
        }
      }
    },
    isSpaceSelectionOpen(value) {
      if (value) {
        this.$refs.listNode.classList.add('active')
        this.$refs.hideText.style.display = 'block'
        this.$refs.requestOfferText.style.display = 'none'
      } else {
        this.$refs.listNode.classList.remove('active')
        this.$refs.requestOfferText.style.display = 'block'
        this.$refs.hideText.style.display = 'none'
      }
    }
  },
  methods: {
    changeViewMode2D() {
      if (this.viewMode === FloorViewMode.TWO_DIMENSIONAL) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: '2d'
          }
        })
      }
      this.$store.dispatch('building/changeViewMode', FloorViewMode.TWO_DIMENSIONAL)
    },
    changeViewMode3D() {
      if (this.viewMode === FloorViewMode.DEFAULT) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: '3d'
          }
        })
      }
      this.$store.dispatch('building/changeViewMode', FloorViewMode.DEFAULT)
    },
    changeViewModeFirstPerson() {
      if (this.viewMode === FloorViewMode.FIRST_PERSON) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: 'VT'
          }
        })
      }
      this.$store.dispatch('building/changeViewMode', FloorViewMode.FIRST_PERSON)
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return
      const modal = this.$store.getters.constants.modal
      this.$store.dispatch(modal.withNamespace(modal.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    toggleSpaceSelectionDetails() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'toggle_space_cart_list',
          payload: {
            opened: !this.isSpaceSelectionOpen
          }
        })
      }
      this.isSpaceSelectionOpen = !this.isSpaceSelectionOpen
      this.showCartSpacesMobile = !this.showCartSpacesMobile
    },
    closeSpaceSelectionDetails() {
      this.isSpaceSelectionOpen = false
      this.showCartSpacesMobile = false
    },
    addCurrentSpaceToCart() {
      const space = {
        ...this.currentSpace,
        building: this.spaceBuilding,
        floor: this.spaceFloor
      }
      this.$store.dispatch(requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE), space)
    },
    removeSpaceFromCart(space) {
      this.$store.dispatch(requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE), space)
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId)
    }
  }
}
